import { trapFocus, unTrapFocus } from 'common/a11y/focus-trap';

import collapsible from 'common/collapsible';
import I18n from 'common/i18n';
import { isLargeDesktop } from 'common/visualizations/helpers/MediaQueryHelper';
import FeatureFlags from 'common/feature_flags';


// This is a hack to make sure users know how many results are present.
// Making the result count visible will activate the aria-alert on the div.
$(window).on('load', () => {
  $('.browse2-result-count.result-count.hidden').removeClass('hidden');
});

// The `no results` span is high enough in the tree
// that when it loads it gets ignored because the screen reader is saying
// something else. That's not how it should work, but...
$(window).on('load', () => {
  setTimeout(() => {
    $('.browse2-no-results-message.hidden').removeClass('hidden');
  }, 3000);
});

$(function() {
  function doBrowse(newOpts) {
    // Reset page
    delete newOpts.page;
    // set utf8
    newOpts.utf8 = '%E2%9C%93';
    // Pull real URL from JS
    window.location = blist.browse.baseURL +
      '?' +
      _.map(
        newOpts,
        function(v, k) {

          if (_.isArray(v)) {
            return _.map(
              v,
              function(subvalue) {
                return k + '=' + subvalue;
              }
            ).join('&');
          }

          return k + '=' + v;
        }
      ).join('&');
  }

  function doSort() {
    _.defer(function() {
      var newOpts = $.extend({}, opts);
      newOpts.sortBy = $sortType.val();
      doBrowse(newOpts);
    });
  }

  function trackClearSearch(event) {
    event.preventDefault();

    _.defer(function() {
      window.location = event.target.href;
    });
  }

  function hookSearch(event) {
    event.preventDefault();

    _.defer(function() {
      var query = $searchSection.find('.browse2-search-control').val();
      var searchOptions = $.extend({}, opts, {
        'q': encodeURIComponent(query)
      });

      if ($.isBlank(searchOptions.q)) {
        delete searchOptions.q;
      } else {
        searchOptions.sortBy = 'relevance';
      }

      var resolveEvent = function() {
        doBrowse(searchOptions);
      };

      resolveEvent();
    });
  }

  function toggleBrowse2FacetDisplay(event, element) {
    element = element || this;
    var $sectionContainer = $(element).closest('.browse2-facet-section');
    var currentDisplay = $sectionContainer.attr('data-facet-display');

    if (currentDisplay === 'show') {
      $sectionContainer.attr('data-facet-display', 'hide');
      $sectionContainer.find('a').first().attr('aria-expanded', 'false');
    } else {
      $sectionContainer.attr('data-facet-display', 'show');
      $sectionContainer.find('a').first().attr('aria-expanded', 'true');
    }

    $sectionContainer.children('ul.browse2-facet-section-options').slideToggle('fast');
  }

  function toggleBrowse2FacetChildOptionDropdown(event) {
    event.preventDefault();
    event.stopPropagation();
    const childOptionToggle = $(event.target).closest('li').find('.browse2-facet-section-child-option-toggle');
    const childOptionExpanded = childOptionToggle.attr('aria-expanded') === 'true';
    childOptionToggle.attr('aria-expanded', !childOptionExpanded);
    $(event.target).closest('li').find('.browse2-facet-section-child-options').slideToggle('fast');
  }

  function toggleBrowse2MobileFacetsSlideout(event) {
    event.preventDefault();
    var $facetPaneSection = $('.browse2-facets-pane, .browse2-mobile-facets-filter-button');
    // Slide out other main elements on page when the mobile facet section slides in
    var $mainPageElements = $(
      ['.siteContentWrapper > ul.featuredViews', '.browse2-search', '.browse2-mobile-filter',
        '.browse2-results-pane', '#siteHeader', '#site-chrome-header', '#siteFooter', '#site-chrome-footer'
      ].join(', ')
    );
    var action = 'slide';
    var time = 200;

    if (FeatureFlags.value('accessible_browse_filters')) {
     var $mobileFiltersSection = $('#mobile-accessible-browse-filters');
     $mobileFiltersSection.show(action, {
      direction: 'left'
      }, time);
      $mainPageElements.hide(action, {
        direction: 'right'
      }, time);
    } else {
    if ($facetPaneSection.is(':visible')) {
      $mainPageElements.show(action, {
        direction: 'right'
      }, time);
      $facetPaneSection.hide(action, {
        direction: 'left'
      }, time);
    } else {
      $facetPaneSection.show(action, {
        direction: 'left'
      }, time);
      $mainPageElements.hide(action, {
        direction: 'right'
      }, time);
    }
    }

  }

  // When the user clicks a filter option in the active filter section, it means they are
  // clearing the filter. This finds the clicked option in the facet dropdown section below
  // and removes the "active" class, then removes the option from the active filter section.
  function browse2MobileActiveFilterClick() {
    const faceOptionType = $(this).data('facetOptionType');
    const faceOptionValue = $(this).data('facetOptionValue');
    var $facetOption = $(`.browse2-facet-section[data-facet-option-type="${faceOptionType}"]`).
    find(
      `.browse2-facet-section-option[data-facet-option-value="${faceOptionValue}"], ` +
      `.browse2-facet-section-child-option[data-facet-option-value="${faceOptionValue}"]`
    );
    $facetOption.removeClass('active');
    // Remove option from active filter section
    $(this).fadeOut('fast');
  }

  function browse2MobileFacetClick(event) {
    // Not MobileMode: return and let the facet follow its href.
    if (!isMobileMode()) {
      return;
    }
    // In MobileMode: prevent the link href and toggle the 'active' class
    event.preventDefault();
    var facetOptionIsCurrentlyActive = $(this).hasClass('active');

    // There can only be one active facet per section. Remove other active facet if any.
    $(this).closest('.browse2-facet-section').
    find('.active').
    removeClass('active');

    if (!facetOptionIsCurrentlyActive) {
      $(this).addClass('active');
    }
  }

  function browse2MobileFacetClearAll(event) {
    event.preventDefault();
    $(
      '.browse2-facet-section-option.active, \
      .browse2-facet-section-child-option.active'
    ).
    removeClass('active');
    filterBrowse2MobileFacets();
  }

  function filterBrowse2MobileFacets() {
    var facetFilters = {};
    var urlParams = {};

    // Get all filter facet options that are currently active and store them in facetFilters
    $('.browse2-facet-section-option.active, .browse2-facet-section-child-option.active').each(function() {
      var paramKey = $(this).closest('.browse2-facet-section').data('facetOptionType');
      var paramValue = $(this).data('facetOptionValue');
      facetFilters[paramKey] = paramValue;
    });

    // parse the old search params and validate them
    var oldUrlParamString = location.search.substring(1);
    if (oldUrlParamString) {
      const oldSearchParams = new URLSearchParams(decodeURI(oldUrlParamString));
      const filters = $('.browse2-facet-section');
      const filterNames = _.map(filters, (filter) => {
        return filter.attributes['data-facet-option-type'].textContent;
      });
      for (let param of oldSearchParams) {
        if (filterNames.indexOf(param[0]) >= 0) {
          urlParams[param[0]] = param[1];
        }
      }
    }

    // Remove all existing filter facets from url params
    $('.browse2-facet-section').each(function() {
      var facetType = $(this).data('facetOptionType');
      delete urlParams[facetType];
    });

    // Also delete the "page" offset parameter if it is present
    delete urlParams.page;

    // Add the current active facetFilters into the urlParams
    urlParams = $.param(_.merge(urlParams, facetFilters));

    // Render a loading spinner and open the new url.
    showBrowse2MobileLoadingSpinner();

    window.addEventListener('pagehide', function() {
      hideBrowse2MobileLoadingSpinner();
    });

    // Safari needs an extra frame before loading the new page to render the loading spinner
    window.setTimeout(function() {
      location.href = `/browse?${urlParams}`;
    }, 0);
  }

  function showBrowse2FacetModal(event) {
    event.preventDefault();
    // Set height of modal based on user's window size
    var modalVerticalMargins = 40;
    var modalHeaderFooterHeight = 120;
    var modalContentMaxHeight = window.innerHeight - (modalVerticalMargins * 2) - modalHeaderFooterHeight;
    $('.browse2-facet-section-modal-container').css({
      'margin': `${modalVerticalMargins}px auto`
    });
    $('.browse2-facet-section-modal-content').css({
      'max-height': `${modalContentMaxHeight}px`
    });
    storeOverflowPropertyInModal('html');
    storeOverflowPropertyInModal('body');
    $('html, body').css('overflow', 'hidden');
    var chosenFacet = $(event.currentTarget).data('modalFacet');
    $(`.browse2-facet-section-modal[data-modal-facet="${chosenFacet}"]`).removeClass('hidden');
    trapFocus($(`.browse2-facet-section-modal[data-modal-facet="${chosenFacet}"] .browse2-facet-section-modal-container`)[0], document.activeElement);
    $('.browse2-facet-section-modal-container h1').trigger('focus');
    $('.siteOuterWrapper').attr('aria-hidden', true);
    hideBrowse2FacetModalOnEscape();
  }

  // Store the overflow property values as data attributes on the facet modal so we can restore
  // it once the modal is hidden.
  function storeOverflowPropertyInModal(element) {
    var overflowX = $(element).css('overflow-x');
    var overflowY = $(element).css('overflow-y');
    $('.browse2-facet-section-modal').data(`${element}OverflowX`, overflowX);
    $('.browse2-facet-section-modal').data(`${element}OverflowY`, overflowY);
  }

  function toggleBrowse2FacetFilterRadioButton(event) {
    event.preventDefault();
    $(event.currentTarget.parentElement).find('a')[0].click();
  }

  function restoreOverflowProperty(element) {
    var overflowX = $('.browse2-facet-section-modal').data(`${element}OverflowX`);
    var overflowY = $('.browse2-facet-section-modal').data(`${element}OverflowY`);
    var defaultOverflow = 'initial';
    $(element).css({
      'overflow-x': overflowX || defaultOverflow,
      'overflow-y': overflowY || defaultOverflow
    });
  }

  function hideBrowse2FacetModalOnEscape() {
    $(document).on('keyup', function(e) {
      if (e.keyCode == 27) {
        hideBrowse2FacetModal();
      }
    });
  }

  function hideBrowse2FacetModal() {
    $('div.browse2-facet-section-modal').addClass('hidden');
    unTrapFocus();
    restoreOverflowProperty('html');
    restoreOverflowProperty('body');
    $('.siteOuterWrapper').attr('aria-hidden', false);
  }

  function truncateDescription(element) {
    const scope = 'controls.browse.browse2.description';
    collapsible(element, {
      lines: 2,
      toggleText: {
        more: I18n.t('show_more_label', { scope }),
        less: I18n.t('show_less_label', { scope })
      }
    });
  }

  function showBrowse2MobileLoadingSpinner() {
    $('.browse2-loading-spinner-container').show();
  }

  function hideBrowse2MobileLoadingSpinner() {
    $('.browse2-loading-spinner-container').hide();
  }

  function isMobileMode() {
    //Styles for the facet filters go into MobileMode below LARGE_DESKTOP_WIDTH
    return (!isLargeDesktop());
  }

  function hideParentAlert(event) {
    $(event.target).closest('.alert').hide();
  }

  function hideCLPManager() {
    document.cookie = 'hide-clp-manager=true; expires=0; path=/';
    $('.browse2-manage-catalog-landing-page').hide();
  }

  function showCLPHelpFlyout() {
    $('#clp-help-flyout').show();
  }

  function hideCLPHelpFlyout() {
    $('#clp-help-flyout').hide();
  }

  var $browse = $('.browse2');
  var opts = {};
  var $sortType = $('#browse2-sort-type');
  var $searchSection = $browse.find('.browse2-search');

  if (!$.isBlank(window.location.search)) {
    _.each(
      window.location.search.slice(1).split('&'),
      function(p) {
        var s = p.split('=');

        s[0] = unescape(s[0]);

        if (/\[\]$/.test(s[0])) {
          if ($.isBlank(opts[s[0]])) {
            opts[s[0]] = [];
          }

          opts[s[0]].push(s[1]);
        } else {
          opts[s[0]] = s[1];
        }
      }
    );
  }

  if ($searchSection.length > 0) {
    $searchSection.on('submit', hookSearch);
    $searchSection.children('.browse2-search-mobile-search-button').on('click', hookSearch);
    $searchSection.children('.browse2-clear-search-icon').on('click', trackClearSearch);
  }

  $sortType.on('change', doSort);

  // blist.iframeHack belongs in the parent window and listens for a modifier key.
  if (window != window.parent &&
    window.parent.blist &&
    window.parent.blist.iframeHack) {

    $browse.on(
      'click',
      'a[rel=external]',
      function(event) {

        if (!window.parent.blist.iframeHack.isModified()) {
          event.preventDefault();
          event.stopPropagation();

          window.parent.location = $(this).attr('href');
        }
      }
    );
  }

  // Expand facet child options list by default if it contains an "active" option
  $('.browse2-facet-section-child-option.active').closest('.browse2-facet-section-child-options').show();

  // Select the radio buttons associated with active facets on load
  $('.browse2-facet-section-option.active, .browse2-facet-section-option.has-child-options.active, .browse2-facet-section-child-option.active').each(function(index, element) {
    $(element.parentElement).children('.browse2-facet-section-option-radio-button').prop('checked', true);
  });

  // Collapse facet options by default in MobileMode
  if (isMobileMode()) {
    toggleBrowse2FacetDisplay(null, $('.browse2-facet-section-title'));
    $('ul.browse2-facet-section-options').hide();
  }

  // Don't forget to also check here: platform-ui/frontend/public/javascripts/catalogLandingPage/catalog.js
  // Result description truncation
  $('.browse2-result-description').each(function(index, element) {
    truncateDescription(element);
  });

  $.fn.extend({
    onClickOrEnter: function(callback) {
      return this.on('click', function(event) { callback(event); }).
        on('keyup', function(event) {
          if (event.keyCode === 13) {
            callback(event);
          }
        });
    }
  });

  // Click listeners
  $('.browse2-facet-section-title').onClickOrEnter(
    function(event) { toggleBrowse2FacetDisplay(event, event.target); });
  $('.browse2-mobile-filter, .browse2-facets-pane-mobile-header').on('click', toggleBrowse2MobileFacetsSlideout);
  $('.browse2-facets-mobile-active-filter').on('click', browse2MobileActiveFilterClick);
  $('.browse2-facet-section-child-option-toggle').onClickOrEnter(
    function(event) { toggleBrowse2FacetChildOptionDropdown(event); });
  $('.browse2-facet-section-option, .browse2-facet-section-child-option').on('click', browse2MobileFacetClick);
  $('.browse2-facet-section-option-radio-button').on('change', function(event) { toggleBrowse2FacetFilterRadioButton(event); });
  $('.browse2-mobile-facets-filter-button').on('click', filterBrowse2MobileFacets);
  $('.browse2-facets-pane-mobile-clear-all-button').on('click', browse2MobileFacetClearAll);
  $('.browse2-facet-section-modal-button').on('click', showBrowse2FacetModal);
  $('.browse2-facet-section-modal-close, .modal-close-button').on('click', hideBrowse2FacetModal);
  $('.browse2-facet-section-modal-background').
    onClickOrEnter(hideBrowse2FacetModal);
  $('.manage-clp-hide-action').onClickOrEnter(hideCLPManager);
  $('.close-alert').onClickOrEnter(
    function(event) { hideParentAlert(event); });
  $('#clp-help-toggle').on('mouseover', showCLPHelpFlyout).on('mouseout', hideCLPHelpFlyout);
});
